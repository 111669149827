import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { title } from "../config";
import { getSupportData } from "../services/supportAPI";


const Support = () => {
    const [support, setSupport] = useState([]);
    const [toggleSorting, setToggleSorting] = useState(0);
    const [loading, setLoading] = useState(true);

    const sortTable = (field) => {
        const sortedData = [...support].sort((a, b) => {
            const compareResult = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            return toggleSorting === 1 ? -compareResult : compareResult;
        });
        setToggleSorting((prev) => (prev === 1 ? 0 : 1));
        setSupport(sortedData);
    };
    const reloadUser = async () => {
        let records = await getSupportData();
        setLoading(false)
        setSupport(records);
    };
    useEffect(() => {
        reloadUser()

    }, [])
    return (
        <>
            <div>
                <div className="px-2">
                    <h3 className="m-0 p-2">{title} | Support Queries </h3>
                    <AppMenu title="Support Queries" />
                </div>
                <span style={{ float: "right", marginRight: "10px" }}>
                    <b>Total:{support.length} &nbsp;&nbsp;</b>
                </span>
                {loading ?
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "75vh",
                        }}
                    >
                        <div className="spinner-border">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className='p-0 m-0 px-2'>
                        <Table>
                            <thead>
                                <tr>
                                    <th onClick={() => sortTable("name")} >Name</th>
                                    <th onClick={() => sortTable("url")} >SiteURL</th>
                                    <th onClick={() => sortTable("email")}>Email</th>
                                    <th onClick={() => sortTable("phone")}>Phone</th>
                                    <th>Time-Date</th>

                                    {/* <th onClick={() => sortTable("assign")}>Issue</th>
                                    <th onClick={() => sortTable("assign")}>Assign </th> */}
                                    <th >Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {support?.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.name} </td>
                                        <td>{item.url} </td>
                                        <td>{item.email} </td>
                                        <td>{item.phone} </td>
                                        <td>{item?.updatedTime ? new Date(item?.updatedTime).toLocaleTimeString() + ' - ' + new Date(item?.updatedTime).toLocaleDateString() : ""} </td>
                                        <td>{item.issue} </td>
                                        {/* <td>{item.assign} </td>
                                        <td>{item.note} </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>

        </>)
}

export default Support