import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/process`;

// this should come from DB 
const allProcesses = [
  {
    _id:'63c56cfb5ae72461a240ce43',
    processName: "Update SSL Certificate",
    createdAt:"",
    createdBy:"userID",
    steps:[
      "scp whitelistingCategories.zip anjali@theaccessmedicine.com:./",
      "ssh  anjali@theaccessmedicine.com",
      "mv /home/anjali/whitelistingCategories.zip /home/cms/",
      "cd chome/cms/",
      "mkdir category-whitelist",
      "mv whitelistingCategories.zip category-whitelist/",
      "cd category-whitelist/",
      "unzip whitelistingCategories.zip", 
      "npm i",
      "cp -r env.txt .env",
      "vim .env (make changes accordingly)",
      "vim index.js",
      "Comment this line await client.hSet('rxs_categories', formattedCategories);",
      "node index.js",
      "vim index.js",
      "Uncomment the line that was commented above.<br/>node index.js<br/>"
    ]
  },
  {
    _id:'63c56cfb5ae72461a240ce73',
    processName: "Document Verification",
  },
  // Add more processes as needed
];

export const getAllProcess = () => {
  return allProcesses;
};


export const getProcessData = async () => {
  const result = await axios.get(serviceEndPoint, headerConfig());
  return result.data;
};
export const addProcessData = async (data) => {
  const result = await axios.post(serviceEndPoint, data, headerConfig());
  return result.data;
};
export const getProcessById = async (siteId) => {
  const result = await axios.get(
    `${serviceEndPoint}/${siteId}`,
    headerConfig()
  );
  return result.data;
};
export const updateProcessData = async (data) => {
  try {
    const result = await axios.put(
      `${serviceEndPoint}/${data._id}`,
      {
        ...data, 
      },
      headerConfig() 
    );
    return result.data;
  } catch (error) {
    console.error("Failed to update process data:", error);
    throw error; // Rethrow error for further handling if needed
  }
};
// export const deleteUser = async (id) => {
//   let data = JSON.stringify({
//     "id": id
//   });
//   console.log("headerConfig()::::>", headerConfig());
//   const result = await axios.delete(serviceEndPoint, data,
//     headerConfig(),
//   );
//   return result.data;
// };

export const deleteProcess = async (id) => {
  try {
    const result = await axios.delete(serviceEndPoint, {
      data: {
        id: id
      },
      ...headerConfig()
    });
    return result.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};