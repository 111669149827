import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/release-records`;

export const getReleaseRecords = async () => {
    const result = await axios.get(serviceEndPoint, headerConfig());
    return result.data;
  };
  export const getReleaseRecordsByReleaseId = async (releaseId) => {
    const result = await axios.get(`${serviceEndPoint}/release/${releaseId}`, headerConfig());
    return result.data;
  };
  export const addReleaseRecords = async (data) => {
    const result = await axios.post(serviceEndPoint, data, headerConfig());
    return result.data;
  };
  export const getReleaseRecordsById = async (id) => {
    const result = await axios.get(
      `${serviceEndPoint}/${id}`,
      headerConfig()
    );
    return result.data;
  };
  export const updateReleaseRecord = async (data) => {
    try {
      const result = await axios.put(
        serviceEndPoint,
        {
          _id: data._id,
          record: {
            releaseName: data.releaseName,
            email: data.email,
            lastUpdated: data.lastUpdated,
            updateBy: data.updateBy,
            features: data.features
          }
        },
        headerConfig()
      );
      return result.data;
    } catch (error) {
      console.error("Error updating process record:", error);
      throw error;
    }
  };
  export const deleteReleaseRecord = async (id) => {
    try {
      const result = await axios.delete(serviceEndPoint, {
        data: {
          id: id
        },
        ...headerConfig()
      });
      return result.data;
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  };