export const siteActiveInactive = [
  { value: 0, name: "Inactive" },
  { value: 1, name: "Active" },
  { value: 2, name: "Active Old " }
];

export const userActiveInactive = [
  { value: 0, name: "Inactive" },
  { value: 1, name: "Active" },
];

export const userRoles = [
  { value: 1, name: "Super Admin" },
  { value: 2, name: "Admin" },
  { value: 3, name: "User" },
  { value: 4, name: "View User" },
];


export const actionDropdown = [
  // { value: 'vu-proxy', name: "VU Proxy" },
  // { value: 'vu-admin', name: "VU Admin" },
  // { value: 'vu-client', name: "VU Client"},
  // { value: 'vu-cms', name: "VU CMS" },
  { value: 'cms-update', name: "CMS-Update" },
  { value: 'others', name: "Others " },
  { value: 'no-action', name: "No-Action " }
];
export const todoDropdown = [
  // { value: 'vuProxy', name: "VU Proxy" },
  // { value: 'vuAdmin', name: "VU Admin" },
  // { value: 'vuClient', name: "VU Client"},
  // { value: 'vuCMS', name: "VU CMS" },
  // { value: 'cmsUpdate', name: "CMS-Update" },
  // { value: 'others', name: "Others" }

];
