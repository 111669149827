export const headerConfig = () => {
  const accessToken = localStorage.getItem("access_token") || null;
  let headersObj = {
    headers: {
      "x-access-token": `${accessToken}`,
      'Content-Type': 'application/json'
    },
  };
  return headersObj;
};
