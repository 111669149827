import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
import { clearLocalStorage } from "./userStorage";
const serviceEndPoint = `${getAPIUrl()}/api/site`;

export const getSiteData = async () => {
  const result = await axios.get(serviceEndPoint, headerConfig());
  if (result?.data?.success == false) {
    clearLocalStorage();
  };
  return result.data;
};
export const addSiteData = async (data) => {
  const result = await axios.post(serviceEndPoint, data, headerConfig());
  return result.data;
};
export const getSiteById = async (siteId) => {
  const result = await axios.get(`${serviceEndPoint}/${siteId}`, headerConfig());
  return result.data;
};
export const updateSiteData = async (data) => {
  const configData = headerConfig();
  const result = await axios.put(
    serviceEndPoint,
    {
      _id: data._id,
      id: data._id,
      name: data.name,
      exDate: data.exDate,
      link: data.link,
      version: data.version,
      databases: data.databases,
      email: data.email,
      active: data.active,
      domain: data.domain,
      comment: data.comment,
      contact: data.contact,
      notes: data.notes,
      redirectLink: data.redirectLink,
      region: data.region,
      cmsActive: data.cmsActive,
      instituteType: data.instituteType,
      action:{
        actionType:data?.action?.actionType,
        timeline:data?.action?.timeline,
        todoAction:data?.action?.todoAction
      },
      domainExpiry:data.domainExpiry
    },
    configData
  );
  return result.data;
};
export const deleteSite = async (id) => {
  const result = await axios.delete(
    `${serviceEndPoint}/${id}`, headerConfig()
  );
  return result.data;
};
