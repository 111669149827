import React from "react";
import { Link } from "react-router-dom";
import { userRoles } from "../services/constant";
import { clearLocalStorage, getUserDetails } from "../services/userStorage";
import { useNavigate } from "react-router";
import { useContext } from "react";
import UserContext from "../components/UserContext";

function AppMenu() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const getRole = (role) => {
    let roleData = userRoles.find((item) => item.value === role);
    if (roleData) {
      return roleData.name;
    } else {
      return "";
    }
  };
  const userDetails = getUserDetails();
  return (
    <>
      <div className="m-0 px-2">
        <Link to={"/dashboard"}>Dashboard</Link> &nbsp;|&nbsp;
        <Link to={"/home"}>Home</Link> &nbsp;|&nbsp;
        <Link to={"/site"}>Sites</Link>&nbsp; | &nbsp;
        <Link to={"/details"}>Details</Link>&nbsp; | &nbsp;
        <Link to={"/support"}>Support</Link>&nbsp; | &nbsp;
        <Link to={"/errors"}>Errors</Link>&nbsp; | &nbsp;
        <Link to={"/memory"}>Memory</Link>&nbsp; | &nbsp;
        <Link to={"/todo"}>Todo</Link>&nbsp; | &nbsp;
        <Link to={"/process"}>Processes</Link>&nbsp; | &nbsp;
        <Link to={"/release"}>Releases</Link>&nbsp; | &nbsp;
        {/* <Link to={"/performance"}>Performance</Link>&nbsp; | &nbsp; */}
      
        {userDetails && userDetails.role <= 2 ? (
          <>
            {" "}
            <Link to="/user">Users</Link> &nbsp; | &nbsp;{" "}
          </>
        ) : (
          ""
        )}
        <Link
          onClick={() => {
            clearLocalStorage();
            userContext.doLogin();
            navigate("/login");
          }}
        >
          {" "}
          Logout
        </Link>
        {userDetails ? (
          <span className="me-3 float-end h5">
            {userDetails && userDetails.name} (
            {userDetails && getRole(userDetails.role)})
          </span>
        ) : (
          ""
        )}
      </div>
      <hr className="p-0 my-2"/>
    </>
  );
}
export default AppMenu;
