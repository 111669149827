import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/process-records`;

// this should come from DB 
const allProcesses = [
{
  _id:'63c56cfb5ae72461a240ce43',
  processName: "Update SSL Certificate",
  createdAt:"",
  createdBy:"userID",
  updatedAt:"",
  updatedBy:"userID",
  steps:[
    "scp whitelistingCategories.zip anjali@theaccessmedicine.com:./",
    "ssh  anjali@theaccessmedicine.com",
    "mv /home/anjali/whitelistingCategories.zip /home/cms/",
    "cd chome/cms/",
    "mkdir category-whitelist",
    "mv whitelistingCategories.zip category-whitelist/",
    "cd category-whitelist/",
    "unzip whitelistingCategories.zip", 
    "npm i",
    "cp -r env.txt .env",
    "vim .env (make changes accordingly)",
    "vim index.js",
    "Comment this line await client.hSet('rxs_categories', formattedCategories);",
    "node index.js",
    "vim index.js",
    "Uncomment the line that was commented above.<br/>node index.js<br/>"
  ],
  qaSteps:[
    "node index.js",
    "vim index.js",
    "scp whitelistingCategories.zip anjali@theaccessmedicine.com:./",
    "ssh  anjali@theaccessmedicine.com",
    "mv /home/anjali/whitelistingCategories.zip /home/cms/",
    "cd chome/cms/",
    "mkdir category-whitelist",
    "mv whitelistingCategories.zip category-whitelist/",
    "cd category-whitelist/",
    "unzip whitelistingCategories.zip", 
    "npm i",
    "cp -r env.txt .env",
    "vim .env (make changes accordingly)",
    "vim index.js",
    "Comment this line await client.hSet('rxs_categories', formattedCategories);",
    "node index.js",
    "vim index.js",
    "Uncomment the line that was commented above.<br/>node index.js<br/>"
  ]
}];
export const getAllProcess = () => {
  return allProcesses;
};


export const getProcessRecords = async () => {
  const result = await axios.get(serviceEndPoint, headerConfig());
  return result.data;
};
export const getProcessRecordsByProcessId = async (processId) => {
  const result = await axios.get(`${serviceEndPoint}/process/${processId}`, headerConfig());
  return result.data;
};
export const addProcessRecords = async (data) => {
  const result = await axios.post(serviceEndPoint, data, headerConfig());
  return result.data;
};
export const getProcessRecordsById = async (id) => {
  const result = await axios.get(
    `${serviceEndPoint}/${id}`,
    headerConfig()
  );
  return result.data;
};
export const updateProcessRecord = async (data) => {
  try {
    const result = await axios.put(
      serviceEndPoint,
      {
        _id: data._id,
        record: {
          processName: data.processName,
          email: data.email,
          lastUpdated: data.lastUpdated,
          updateBy: data.updateBy,
          siteName: data.siteName,
          steps: data.steps
        }
      },
      headerConfig()
    );
    return result.data;
  } catch (error) {
    console.error("Error updating process record:", error);
    throw error;
  }
};

// export const deleteUser = async (id) => {
//   let data = JSON.stringify({
//     "id": id
//   });
//   console.log("headerConfig()::::>", headerConfig());
//   const result = await axios.delete(serviceEndPoint, data,
//     headerConfig(),
//   );
//   return result.data;
// };

export const deleteProcessRecord = async (id) => {
  try {
    const result = await axios.delete(serviceEndPoint, {
      data: {
        id: id
      },
      ...headerConfig()
    });
    return result.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};