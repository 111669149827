import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/qa-records`;

// this should come from DB 
const allQAes = [
  {
    _id:'63c56cfb5ae72461a240ce43',
    qaName: "Update SSL Certificate",
    createdAt:"",
    createdBy:"userID",
    steps:[
      "scp whitelistingCategories.zip anjali@theaccessmedicine.com:./",
      "ssh  anjali@theaccessmedicine.com",
      "mv /home/anjali/whitelistingCategories.zip /home/cms/",
      "cd chome/cms/",
      "mkdir category-whitelist",
      "mv whitelistingCategories.zip category-whitelist/",
      "cd category-whitelist/",
      "unzip whitelistingCategories.zip", 
      "npm i",
      "cp -r env.txt .env",
      "vim .env (make changes accordingly)",
      "vim index.js",
      "Comment this line await client.hSet('rxs_categories', formattedCategories);",
      "node index.js",
      "vim index.js",
      "Uncomment the line that was commented above.<br/>node index.js<br/>"
    ]
  },
  {
    _id:'63c56cfb5ae72461a240ce73',
    qaName: "Document Verification",
  },
  // Add more qaes as needed
];

export const getAllQA = () => {
  return allQAes;
};


export const getQARecords = async () => {
  const result = await axios.get(serviceEndPoint, headerConfig());
  return result.data;
};
export const getQARecordsByProcessId = async (qaId) => {
  const result = await axios.get(`${serviceEndPoint}/qa/${qaId}`, headerConfig());
  return result.data;
};
export const addQARecords = async (data) => {
    console.log("data",data);
    
  const result = await axios.post(serviceEndPoint, data, headerConfig());
  return result.data;
};
export const getQARecordsById = async (id) => {
  const result = await axios.get(
    `${serviceEndPoint}/${id}`,
    headerConfig()
  );
  return result.data;
};
export const updateQARecord = async (data) => {
  try {
    const result = await axios.put(
      serviceEndPoint,
      {
        _id: data._id,
        record: {
          qaName: data.qaName,
          email: data.email,
          lastUpdated: data.lastUpdated,
          updateBy: data.updateBy,
          siteName: data.siteName,
          steps: data.steps
        }
      },
      headerConfig()
    );
    return result.data;
  } catch (error) {
    console.error("Error updating qa record:", error);
    throw error;
  }
};

// export const deleteUser = async (id) => {
//   let data = JSON.stringify({
//     "id": id
//   });
//   console.log("headerConfig()::::>", headerConfig());
//   const result = await axios.delete(serviceEndPoint, data,
//     headerConfig(),
//   );
//   return result.data;
// };

export const deleteQARecord = async (id) => {
  try {
    const result = await axios.delete(serviceEndPoint, {
      data: {
        id: id
      },
      ...headerConfig()
    });
    return result.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};