import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { addSiteData, getSiteById, updateSiteData } from "../services/siteAPI";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import Select from "react-select";
import { actionDropdown, siteActiveInactive } from "../services/constant";
import { getUserDetails } from "../services/userStorage";
import moment from "moment";
import { Table } from "reactstrap";

export default function AddSite(props) {
  const { recordId } = useParams();
  const [active, setActive] = useState([]);
  const [actionOption, setActionOption] = useState([]);
  const userDetails = getUserDetails();


  const [site, setSite] = useState({
    name: "",
    link: "",
    version: "",
    databases: "",
    active: "",
    domain: "",
    email: "",
    comment: "",
    exDate: "",
    notes: [],
  });

  const [buttonLabel, setBLabel] = useState("Add");
  const loadSite = async (id) => {
    let record = await getSiteById(id);
    setSite(record);
    setBLabel("Update");
  };
  const loadData = async () => {
    let status = siteActiveInactive;
    let actions = actionDropdown;
    setActionOption(actions);
    setActive(status);
  };

  useEffect(() => {
    if (recordId) {
      loadSite(recordId);
    }
    loadData();
  }, []);
  const handleChange = (e) => {
    setSite({ ...site, [e.target.name]: e.target.value });
  };
  // const handleActionChange = (e) => {
  //   setSite({ ...site, action:{ [e.target.name] : e.value }});
  // };
  const handleActionChange = (e) => {
    const name = e.target ? e.target.name : e.name;
    const value = e.target ? e.target.value : e.value;
    console.log("Name:>>", name, value);
    setSite((prevSite) => ({
      ...prevSite,
      action: {
        ...prevSite.action,
        [name]: value,
      },
    }));
  };
  const handleActiveChange = (e) => {
    setSite({ ...site, active: e.value });
  };
  const handleCMSActiveChange = (e) => {
    setSite({ ...site, cmsActive: e.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!site.name.length) {
      return;
    }
    const newItem = { ...site, notetime: new Date() };
    newItem.useremail = userDetails.email;
    newItem.username = userDetails.name;
    if (site._id !== undefined) {
      const updatedData = await updateSiteData(newItem);
      setSite(updatedData);
    } else {
      await addSiteData(newItem);
    }
    window.history.back();
  };
  return (
    // <div className="px-2">
    //   <h3>
    //     {title} ({buttonLabel} )
    //   </h3>
    //   <AppMenu title="Add Site" />
    <div>
      <div className="px-2">
        <h3 className="m-0 p-2">{title} | {buttonLabel} Site</h3>
        <AppMenu />
      </div>
      <div className="p-0 m-0 px-2 pb-2">
        <div className="card p-5">
          <form className="container float-center needs-validation">
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-3">
                <label className="form-label">Name</label>
                <input
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={site.name}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Link</label>
                <input
                  placeholder="Link"
                  name="link"
                  onChange={handleChange}
                  value={site.link}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-2 col-sm-6 mb-3">
                <label className="form-label">Version</label>
                <input
                  placeholder="Version"
                  name="version"
                  onChange={handleChange}
                  value={site.version}
                  className="form-control"
                />
              </div>

              <div className="col-md-2 col-sm-6  mb-3">
                <label className="form-label">Databases</label>
                <input
                  placeholder="Databases"
                  name="databases"
                  onChange={handleChange}
                  value={site.databases}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Proxy Active</label>
                <Select
                  name="active"
                  className="basic-single mb-0"
                  options={active}
                  getOptionValue={(active) => active.value}
                  getOptionLabel={(active) => active.name}
                  onChange={handleActiveChange}
                  autoFocus={true}
                  value={active.find((i) => i.value == site.active)}
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Domain</label>
                <input
                  placeholder="Domain"
                  name="domain"
                  onChange={handleChange}
                  value={site.domain}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 col-sm-6  mb-3">
                <label className="form-label">Email</label>
                <input
                  placeholder="Email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={site.email}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 col-sm-6  mb-3">
                <label className="form-label">Comment</label>
                <input
                  placeholder="Comment"
                  name="comment"
                  onChange={handleChange}
                  value={site.comment}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Redirect Link</label>
                <input
                  placeholder="Redirect Link"
                  name="redirectLink"
                  onChange={handleChange}
                  value={site.redirectLink}
                  className="form-control"
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Contact</label>
                <input
                  placeholder="contact"
                  name="contact"
                  onChange={handleChange}
                  value={site.contact}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 col-sm-6  mb-3">
                <label className="form-label">Cert. Exp. Date</label>
                <input
                  type="date"
                  placeholder="Date"
                  name="exDate"
                  onChange={handleChange}
                  value={site.exDate}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 col-sm-6  mb-3">
                <label className="form-label">Region</label>
                <input
                  placeholder="Region"
                  name="region"
                  onChange={handleChange}
                  value={site.region}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">CMS Active</label>
                <Select
                  name="cmsActive"
                  className="basic-single mb-0"
                  options={active}
                  getOptionValue={(active) => active.value}
                  getOptionLabel={(active) => active.name}
                  onChange={handleCMSActiveChange}
                  autoFocus={true}
                  value={active.find((i) => i.value == site.cmsActive)}
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Institute Type</label>
                <input
                  placeholder="Institute Type"
                  name="instituteType"
                  onChange={handleChange}
                  value={site.instituteType}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 col-sm-6  mb-3">
                <label className="form-label">Domain Expiry</label>
                <input
                  type="date"
                  placeholder="Domain Expiry"
                  name="domainExpiry"
                  onChange={handleChange}
                  value={site.domainExpiry}
                  className="form-control"
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Action</label>
                <Select
                  name="actionType"
                  className="basic-single mb-0"
                  options={actionOption}
                  getOptionValue={(actionOption) => actionOption.value}
                  getOptionLabel={(actionOption) => actionOption.name}
                  onChange={(selectedOption) =>
                    handleActionChange({ name: "actionType", value: selectedOption.value })
                  } autoFocus={true}
                  value={actionOption.find((i) => i.value == site?.action?.actionType)}
                />
              </div>
              <div className="col-md-3 col-sm-6  mb-3">
                <label className="form-label">Todo Action</label>
                <input
                  placeholder="Todo Action"
                  name="todoAction"
                  onChange={handleActionChange}
                  value={site?.action?.todoAction}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 col-sm-6  mb-3">
                <label className="form-label">Todo TimeLine</label>
                <input
                  type="date"
                  placeholder="Todo TimeLine"
                  name="timeline"
                  onChange={handleActionChange}
                  value={site?.action?.timeline}
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="col-md-2 col-sm-6">
              <input
                type="button"
                onClick={handleSubmit}
                value={buttonLabel}
                className="btn  btn-primary btn-block"
              />
            </div>
            &nbsp;&nbsp; &nbsp;&nbsp;
          </form>
        </div>
      </div>
      <div className="p-2">
        <div className="card ps-5 pe-5">
          <AddNotes site={site} userDetails={userDetails} />
          <NoteList items={site.notes} />
        </div>
      </div>
    </div>
  );
}

const NoteList = ({ items }) => {
  return (

    <Table className="table table-hover" hover responsive>
      <thead>
        <tr>
          <th>Time</th>
          <th>Username</th>
          <th>Freshdesk Link</th>
          <th>Action</th>
          <th>Note</th>
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((item,index) => (
            <tr key={index}>
              <td>
                {item.time &&
                  moment(item.time)
                    .zone("Asia/Kolkata")
                    .format("hh:mm A - DD/MM/YYYY")}
              </td>
              <td>{item.userName} </td>
              <td>{item.freshdeskLink} </td>
              <td>{item.action} </td>
              <td>{item.note} </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const AddNotes = ({ site, userDetails }) => {
  const [noteValues, setNoteValues] = useState({
    note: "",
    freshdeskLink: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNoteValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const AddNotes = () => {
    const newNote = {
      time: new Date(),
      id: Date.now(),
      userName: userDetails.name,
      userId: userDetails._id,
      note: noteValues.note,
      action: noteValues.action,
      freshdeskLink: noteValues.freshdeskLink,
    };
    site.notes = site.notes || [];
    site.notes.unshift(newNote);
    updateSiteData(site);
    window.location.reload();
  };
  return (
    <form className="container float-center needs-validation">
      <div className="row mt-4">
        <div className="col-md-6 col-sm-12 mb-3">
          <label className="form-label">Note</label>
          <textarea
            placeholder="note"
            type="textarea"
            name="note"
            onChange={handleChange}
            value={noteValues.note}
            className="form-control"
            required
          />{" "}
        </div>
        <div className="col-md-3 col-sm-6 mb-3">
          <label className="form-label">Freshdesk Link</label>
          <input
            placeholder="freshdeskLink"
            name="freshdeskLink"
            onChange={handleChange}
            value={noteValues.freshdeskLink}
            className="form-control"
            required
          />
        </div>
        <div className="col-md-3 col-sm-6 mb-3">
          <label className="form-label">Action</label>
          <input
            placeholder="Action"
            name="action"
            onChange={handleChange}
            value={noteValues.action}
            className="form-control"
            required
          />
        </div>
      </div>
      <div className="col-md-2 col-sm-6 mb-3">
        <input
          type="button"
          value="Add Note"
          className="btn btn-block btn-secondary"
          onClick={() => {
            AddNotes();
          }}
        /></div>
    </form>
  );
};
