import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { deployAPI } from '../services/Deploy';

const DeployDialog = ({ deployData }) => {
    const [isLoading, setisLoading] = useState(false);
    const [deployResult, setDeployResult] = useState("");
    const [updateTitle, setUpdateTitle] = useState('');
    const dynamicStyles = `.pre {
        font-size: 'auto';
        }`;
    const onDeploy = async (endpoint, title) => {
        setisLoading(true);
        setUpdateTitle(title);
        const url = `https://core.${deployData.domain}/${endpoint}`
        const result = await deployAPI(url);
        setDeployResult(result);
        setisLoading(false);
    }

    useEffect(() => {
        setUpdateTitle("");
        setDeployResult("");
    }, [deployData]);

    return (
        <>
            <style>{dynamicStyles}</style>
            <div className="modal fade" id="deployModal" tabIndex="-1" aria-labelledby="deployModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="deployModalLabel">Upgrade</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ minHeight: "50vh" }}>
                            <div className='text-center p-3'>
                                <h3>{deployData?.name}</h3>
                            </div>
                            <div className="d-grid gap-2 d-flex justify-content-center">
                                {deployData.active == 1 ? <button type="button" className="btn btn-warning" onClick={() => { onDeploy('check-update', 'Update Proxy') }}>Update Proxy</button> : <p> New Porxy is not available or Old Proxy </p>}
                                {deployData.cmsActive == 1 ?
                                    <>
                                        <button type="button" className="btn btn-warning" onClick={() => { onDeploy('cms-update', 'Update CMS') }}>Update CMS</button>
                                        <button type="button" className="btn btn-warning" onClick={() => { onDeploy('client-update', 'Update Client') }}>Update Client</button>
                                        <button type="button" className="btn btn-warning" onClick={() => { onDeploy('admin-update', 'Update Admin') }}>Update Admin</button>
                                    </>
                                    : <p> New CMS is not available</p>
                                }
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
                                {isLoading ? (
                                    <div>
                                        <div className="spinner-border">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ maxHeight: "400px", overflowY: "auto", textAlign: 'center' }}>
                                        {deployResult &&
                                            <>
                                                <h5 className="status" style={{
                                                    flexWrap: "wrap",
                                                    alignContent: "stretch",
                                                    justifyContent: "center",
                                                }} dangerouslySetInnerHTML={{ __html: `${deployResult}` }} />
                                                {deployResult === "<pre>ok</pre>" ? (
                                                    <div>
                                                        <h5 className="text-success"> {updateTitle} was successful.</h5>
                                                    </div>
                                                ) : (
                                                    <div className='font'>
                                                        <h5 className="failure text-danger">{updateTitle} was not successful.</h5>
                                                    </div>
                                                )}
                                            </>

                                        }
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DeployDialog