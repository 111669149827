import axios from "axios";
import { getAPIUrl } from "../config";
import { headerConfig } from "./authConfig";
const serviceEndPoint = `${getAPIUrl()}/api/release`;


// export const getAllReleases = () => {
//     return allRelease;
//   };
export const getReleaseData = async () => {
    const result = await axios.get(serviceEndPoint, headerConfig());
    return result.data;
  };
  export const addReleaseData = async (data) => {
    const result = await axios.post(serviceEndPoint, data, headerConfig());
    return result.data;
  };
  export const getReleaseById = async (id) => {
    const result = await axios.get(
      `${serviceEndPoint}/${id}`,
      headerConfig()
    );
    return result.data;
  };
  export const updateReleaseData = async (data) => {
    try {
      const result = await axios.put(
        `${serviceEndPoint}/${data._id}`,
        {
          ...data, 
        },
        headerConfig() 
      );
      return result.data;
    } catch (error) {
      console.error("Failed to update Release data:", error);
      throw error; // Rethrow error for further handling if needed
    }
  };
  export const deleteRelease = async (id) => {
    try {
      const result = await axios.delete(serviceEndPoint, {
        data: {
          id: id
        },
        ...headerConfig()
      });
      return result.data;
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  };