import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap';
import DeployDialog from './DeployDialog';
import moment from "moment";
import UserInfoBySite from './UserInfoBySite';

const SiteListTable = ({ items, doDelete, doEdit, doHistory, doUpgrade }) => {
    const [localItems, setlocalItems] = useState([...items]);
    const [toggleSorting, setToggleSorting] = useState(0);
    const [dynamicStyles, setDynamicStyles] = useState();
    const [deployData, setDeployData] = useState('');
    const getFreeSpace = (free, size) => {
        const percentageFull = ((size - free) / size) * 100;
        const roundValue = Math.round(percentageFull);
        return roundValue;
    };

    useEffect(() => {
        const styles = `.cursor-pointer
          { cursor: pointer;}`;
        setDynamicStyles(styles);
        setlocalItems(items);
    }, [items]);

    const sortTable = (field) => {
        let sortedData = [];

        if (toggleSorting === 1) {
            sortedData = [...localItems].sort((a, b) => a[field] > b[field] ? 1 : -1);
            setToggleSorting(0);
        } else {
            sortedData = [...localItems].sort((a, b) => b[field] > a[field] ? 1 : -1);
            setToggleSorting(1);
        }
        setlocalItems(sortedData);
    }
    const onDeploy = (rowData) => {
        setDeployData(rowData)
    }
    return (
        <div className="p-0 m-0 px-2">
            <style>{dynamicStyles}</style>
            <Table className="table table-hover" responsive hover>
                <thead>
                    <tr>
                        <th onClick={() => sortTable("name")} className="cursor-pointer">Name</th>
                        <th>
                            <span onClick={() => sortTable("cms")} className="cursor-pointer">CMS</span> |
                            <span onClick={() => sortTable("status")} className="cursor-pointer">Proxy</span>
                        </th>
                        <th onClick={() => sortTable("exDate")} className="cursor-pointer">Cert. Exp</th>
                        <th onClick={() => sortTable("domain")} className="cursor-pointer">Domain</th>
                        <th>Domain Exp.</th>
                        <th onClick={() => sortTable("exec_mode")} className="cursor-pointer">Ex.mode</th>
                        <th onClick={() => sortTable(getFreeSpace('free', 'size'))} className="cursor-pointer">Memory</th>
                        <th onClick={() => sortTable("osUptime")} className="cursor-pointer">OS Uptime</th>
                        <th onClick={() => sortTable("nodeUptime")} className="cursor-pointer">Proxy Uptime</th>
                        <th>
                            <span onClick={() => sortTable("databases")} className="cursor-pointer">DBs</span> |
                            <span onClick={() => sortTable("redisKeyCount")} className="cursor-pointer"> RedisKey </span>
                        </th>
                        <th>
                            <span onClick={() => sortTable("active")} className="cursor-pointer">P.Active </span> |
                            <span onClick={() => sortTable("cmsActive")} className="cursor-pointer"> CMS </span>
                        </th>
                        <th>Proxy V.</th>
                        <th>CMS V.</th>
                        <th>Client V.</th>
                        <th>Admin V.</th>
                        <th onClick={() => sortTable("node_version")} className="cursor-pointer">Node V.</th>
                        <th>Login Count
                        <span>24 Hr. </span> |
                        <span>Last Hr.</span>
                        </th>
                        <th>  
                            <span>Data Usage Combine</span> |
                            <span>Download</span>
                        </th>
                        <th>I.Type</th>
                        <th>R.Link</th>
                        <th>
                            <span onClick={() => sortTable("item?.siteUserDetails?.activeUserCount")} className="cursor-pointer">A.User</span>|
                            <span onClick={() => sortTable("item?.siteUserDetails?.currentSessionCount")} className="cursor-pointer"> Session </span>
                        </th>
                        <th>
                            <span onClick={() => sortTable("item?.siteUserDetails?.activeUserCount")} className="cursor-pointer">NoSigin</span> |
                            <span onClick={() => sortTable("item?.siteUserDetails?.currentSessionCount")} className="cursor-pointer"> Sometime </span>
                        </th>
                        <th onClick={() => sortTable("region")} className="cursor-pointer">Region</th>
                        <th onClick={() => sortTable("diffPM2ErrSize")} className="cursor-pointer">Diff. Error | Out </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {localItems.map((item) => (
                        <tr key={item._id}>
                            <td>{item.name}</td>
                            <td> {item.cms}|{item.status}</td>
                            <td>{moment(item.exDate).format("DD/MM/YYYY")}</td>
                            <td>
                                <a href={`https://${item.domain}`} target="_blank">{item.domain}</a>
                            </td>
                            <td>{item.domainExpiry}</td>
                            <td>{item.exec_mode}</td>
                            <td>
                                {`${item.free
                                    ? getFreeSpace(item.free, item.size) +
                                    "%" +
                                    " (" +
                                    (item.size / 1073741824).toFixed(0) +
                                    "GB)"
                                    : " "
                                    }`}
                            </td>
                            <td>
                                {item.osUptime
                                    ? Math.round(item.osUptime / (60 * 60 * 24)).toFixed(0)
                                    : " -"}{" "}
                                Days
                            </td>
                            <td>
                                {item.nodeUptime
                                    ? Math.round(item.nodeUptime / (60 * 60)).toFixed(0)
                                    : " -"}{" "}
                                Hrs
                            </td>
                            <td>{item.databases ? item.databases : "-"} | {item.redisKeyCount ? item.redisKeyCount : "-"}</td>
                            <td>{item.active} | {item?.cmsActive}</td>
                            <th>{item?.version} | {item?.date}</th>
                            <th>{item?.customVersion?.version} | {item?.customVersion?.date}</th>
                            <th>{item?.newCmsVersion?.version} | {item?.newCmsVersion?.date}</th>
                            <th>{item?.adminVersion?.version} | {item?.adminVersion?.date}</th>
                            <td>{item.node_version ? item.node_version : " "}</td>
                            <td>{item?.reportVersion?.login ? item.reportVersion.login.count  +" | "+item.reportVersion?.login?.lastHourCount  : " "}</td>
                            <td>{item.reportVersion?.data ? item.reportVersion?.data?.combineData  +" | "+item.reportVersion?.data?.downloadData  : " "}</td>
                            <td>{item.instituteType}</td>
                            <td>{item.redirectLink ? item.redirectLink : "-"}</td>
                            <td>
                                {item?.siteUserDetails?.activeUserCount ? item?.siteUserDetails?.activeUserCount : "-"} |
                                {item?.siteUserDetails?.currentSessionCount ? item?.siteUserDetails?.currentSessionCount : "-"}
                            </td>
                            <td>
                                {item?.siteUserDetails?.notLoggedInUsers ? item?.siteUserDetails?.notLoggedInUsers : "-"} |
                                {item?.siteUserDetails?.loginCounLessThanFive ? item?.siteUserDetails?.loginCounLessThanFive : "-"}
                            </td>
                            <td>{item.region ? item.region : "-"}</td>
                            <td>{(item.diffPM2ErrSize / (1024 ** 2)).toFixed(2)}| {(item.diffPM2OutSize / (1024 ** 2)).toFixed(2)} MB</td>
                            <td className="">
                                <div className="dropdown float-end pe-2">
                                    <a href="#" role="button" data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fa-solid fa-bars"></i>
                                    </a>
                                    <div className="dropdown-menu p-2">
                                        <ul className='d-flex p-0 m-0'>
                                            {/* href={doUpgrade(item.domain)} target={"_blank"}> */}
                                            <i className="fa-solid fa-cloud-arrow-up btn btn-dark m-2"
                                                data-bs-toggle="modal" data-bs-target="#deployModal" onClick={() => setDeployData(item)}></i>
                                            <i
                                                className="fa-solid fa-clock-rotate-left btn btn-warning m-2 "
                                                onClick={() => doHistory(item._id)}
                                            ></i>
                                            <i
                                                className="fas fa-edit btn btn-primary m-2 "
                                                onClick={() => doEdit(item._id)}
                                                aria-hidden="true"
                                            ></i>
                                            <i
                                                className="fa-solid fa-trash-can btn btn-danger m-2"
                                                onClick={() => doDelete(item._id)}
                                                aria-hidden="true"
                                            ></i>
                                            <i
                                                className="fa-solid fa fa-ellipsis-v btn btn-dark m-2"
                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                aria-hidden="true"
                                                onClick={() => setDeployData(item)}
                                            ></i>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <th></th>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <DeployDialog deployData={deployData} />
            <UserInfoBySite deployData={deployData} />
        </div>
    );
}
export default SiteListTable
