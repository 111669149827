import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { getStatesById } from "../services/stateAPI";
import { getSiteData } from "../services/siteAPI";
import moment from "moment";
import { useParams } from "react-router";
import { title } from "../config";

const SiteLog = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  let { id } = useParams();
  const reloadSite = async (id) => {
    try {
      setLoading(true);
      if (id !== undefined) {
        const data = await getStatesById(id);
        setItems(data);
      }
      else {
        const data = await getSiteData();
        setItems(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reloadSite(id);
  }, [id]);
  // const reloadSite = async (id) => {
  //   if (id !== undefined) {
  //     setItems(await getStatesById(id));
  //   } else {
  //     setItems(await getSiteData());
  //   }
  // };

  return (
    <div>
      <div className="px-2">
        <h3 className="m-0 p-2">{title} | Details</h3>
        <AppMenu title="details" />
      </div>
      <StateList items={items} loading={loading} />
    </div>
  );
}
const outLogURL = (domain) => {
  const url = `https://core.${domain}/out-log`;
  return url;
};
const errorLogURL = (domain) => {
  const url = `https://core.${domain}/error-log`;
  return url;
};

const StateList = ({ items, loading }) => {
  const [localItems, setlocalItems] = useState([...items]);
  const [toggleSorting, setToggleSorting] = useState(0);
  useEffect(() => {
    setlocalItems(items);
  }, [items]);
  const sortTable = (field) => {
    let sortedData = [];
    if (toggleSorting === 1) {
      sortedData = [...localItems].sort((a, b) => {
        if (a[field] > b[field]) {
          return -1;
        } else if (a[field] < b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      setToggleSorting(0);
    } else {
      sortedData = [...localItems].sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      setToggleSorting(1);
    }
    setlocalItems(sortedData);
  };
  return (
    <>
      {loading ?
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "75vh",
          }}
        >
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        :
        <div className="p-0 m-0 px-2">
        <Table>
          <thead>
            <tr>
              <th onClick={() => sortTable("name")}>Name</th>
              <th onClick={() => sortTable("version")}>Version</th>
              <th onClick={() => sortTable("status")}>Status</th>
              <th onClick={() => sortTable("time")}>Time </th>
              <th onClick={() => sortTable("cpuLoad")}>CPU-Usage </th>
              <th onClick={() => sortTable("exec_mode")}>Exec Mode </th>
              <th onClick={() => sortTable("freeMem")}>free M/ total M</th>
              <th onClick={() => sortTable("pm2ErrSize")}>Error </th>
              <th onClick={() => sortTable("pm2OutSize")}>PM2 Out</th>
              <th>OutLog</th>
              <th>ErrLog</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {localItems.map((item) => (
              <tr key={item._id}>
                <td>{item.name} </td>
                <td>{item.version} </td>
                <td>{item.status} </td>
                <td>
                  {
                    moment(item.time)
                      .zone("Asia/Kolkata")
                      .format("hh:mm A - DD/MM/YYYY")
                    // { moment.utc(item.time, 'hh:mm A').zone('Asia/Kolkata').format("hh:mm A- DD/MM/YYYY") }
                  }
                </td>
                <td>{item.cpuLoad ? item.cpuLoad.join(' / ') : '-'}</td>
                <td>{item.exec_mode} </td>
                <td>
                  {item.freeMem} / {item.totalMem}
                </td>
                <td>{item.pm2ErrSize}</td>
                <td>{item.pm2OutSize} </td>
                <td>
                  <a href={outLogURL(item.domain)}>
                    <i
                      className="fa-solid fa-gears btn btn-success"
                      aria-hidden="true"
                    ></i>
                  </a>
                </td>

                <td>
                  <a href={errorLogURL(item.domain)}>
                    <i
                      className="fa-solid fa-circle-exclamation btn btn-danger"
                      aria-hidden="true"
                    ></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
      }</>
  );
};

export default SiteLog;