import React, { useEffect } from "react";
import { getAPIUrl } from "../config";
import { useNavigate } from "react-router";
import { useContext } from 'react';
import UserContext from "../components/UserContext";
import "./Login.css";

const goLogin = async (data) => {
  let response = await fetch(getAPIUrl() + `/users/authenticate`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

function Login() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const usernameInput = React.createRef();
  const passwordInput = React.createRef();
  const doLogin = async () => {
    const data = {
      username: usernameInput.current.value,
      password: passwordInput.current.value,
    };
    let result = await goLogin(data);
    if (result.res) {
      userContext.doLogin(result);
     window.location.href="/home";
      // navigate("/home");
    } else {
      alert(result.msg);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      doLogin();
    }
  };
  // useEffect(()=>{
  //   // document.body.style.background = 'radial-gradient(#9e1f63, #282159c9)'; 
  // },[]);

  return (
    <div style={{background :'radial-gradient(#9e1f63, #282159c9)',height:"100vh" }}>
    <div className="d-grid justify-content-center justify-content" style={{height:"85vh",alignItems:'center'}}>
      <div style={{
        background: '#a4a5d066',
        borderRadius: '10px',
        boxShadow: '0 32px 64px #0003',
        padding: '50px',
        width: '400px'
      }}>
        <div className="form-signin">
          <h2 className="form-signin-heading text-white">RemoteXS Monitor</h2>
        </div>
        <form className="form-signin">
          <input
            ref={usernameInput}
            placeholder="username"
            className="form-control"
            name=""
          />
          <br />
          <br />
          <input
            ref={passwordInput}
            type="password"
            placeholder="password"
            className="form-control"
            name=""
            onKeyDown={handleKeyDown}
          />{" "}
          <br />
          <br />
          <input
            type="button"
            className="btn btn-lg btn-outline-light btn-block"
            onClick={doLogin}
            value="Login"
          />

        </form>
      </div>
    </div>
    </div>
  );
}
export default Login;
