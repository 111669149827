import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './containers/Home';
import PageNotFound from './containers/PageNotFound';
import UserCtx from './components/UserContext';
import { Site } from './containers/Site'
import SiteLog from './containers/SiteLogs'
import { State } from './containers/State'
import AddSite from './containers/AddSite';
import Adduser from './containers/AddUser';
import { User } from './containers/User';
import Login from './containers/Login';
import Protected from './components/Protected';
import ErrorSite from './containers/ErrorSite';
import './style.css'
import Support from './containers/Support';
import Memory from './containers/Memory';
import { SiteTodo } from './containers/SiteTodo';
import Dashboard from './containers/Dashboard';
import Performance from './containers/Performance';
import Processes from './containers/Processes';
import AddProcessRecords from './containers/AddProcessRecords';
import Records from './containers/Records';
import AddProcess from './containers/AddProcess';
import Release from './containers/Release';
import AddRelease from './containers/AddRelease';
import AddReleaseRecord from './containers/AddReleaseRecord';

const loginStatus = () => {
  if (localStorage.getItem('isLoggedIn') === 'true') {
    return true;
  } else {
    return false;
  }
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(loginStatus());
  return (
    <Router>
      <UserCtx.Provider
        value={{
          isLoggedIn,
          doLogin: (result) => {
            if (result) {
              localStorage.setItem("access_token", result.token);
              localStorage.setItem("user", JSON.stringify(result.user));
              localStorage.setItem('isLoggedIn', "true");
              setIsLoggedIn(true);
            } else {
              localStorage.removeItem('isLoggedIn');
              localStorage.removeItem("access_token");
              localStorage.removeItem("user");
              setIsLoggedIn(false)
            }
          }
        }}
      >
        <Routes>
          <Route exact path="/" element={<Navigate to={{ pathname: "/home" }} />} />
          <Route exact path="/home" element={
            <Protected isLoggedIn={isLoggedIn}><Home /></Protected>
          } />
          <Route exact path="/dashboard" element={
            <Protected isLoggedIn={isLoggedIn}><Dashboard /></Protected>
          } />
          <Route exact path="/site" element={
            <Protected isLoggedIn={isLoggedIn}><Site /></Protected>
          } />
          <Route exact path="/site/add" element={
            <Protected isLoggedIn={isLoggedIn}><AddSite /></Protected>
          } />
          <Route exact path="/site/edit/:recordId" element={
            <Protected isLoggedIn={isLoggedIn}><AddSite /></Protected>
          } />
          <Route exact path="/sites/:field/:value" element={
            <Protected isLoggedIn={isLoggedIn}><Site /></Protected>
          } />
          <Route exact path="/details" element={
            <Protected isLoggedIn={isLoggedIn}><SiteLog /></Protected>
          } />
          <Route exact path="/errors" element={
            <Protected isLoggedIn={isLoggedIn}><ErrorSite /></Protected>
          } />
          <Route exact path="/support" element={
            <Protected isLoggedIn={isLoggedIn}><Support /></Protected>
          } />
          <Route exact path="/performance" element={
            <Protected isLoggedIn={isLoggedIn}><Performance /></Protected>
          } />
          <Route exact path="/state/:id" element={<State />} />
          <Route exact path="/user" element={
            <Protected isLoggedIn={isLoggedIn}><User /></Protected>
          } />
          <Route exact path="/user/add" element={
            <Protected isLoggedIn={isLoggedIn}><Adduser /></Protected>
          } />
          <Route exact path="/memory" element={
            <Protected isLoggedIn={isLoggedIn}><Memory /></Protected>
          } />
          <Route exact path="/user/edit/:recordId" element={
            <Protected isLoggedIn={isLoggedIn}><Adduser /></Protected>
          } />
          <Route exact path="/todo" element={
            <Protected isLoggedIn={isLoggedIn}><SiteTodo /></Protected>
          } />
          <Route exact path="/todo/:field/:value" element={
            <Protected isLoggedIn={isLoggedIn}><SiteTodo /></Protected>
          } />
          <Route exact path="/process" element={
            <Protected isLoggedIn={isLoggedIn}><Processes /></Protected>
          } />
          <Route exact path="/process/record/:processId/add" element={
            <Protected isLoggedIn={isLoggedIn}><AddProcessRecords /></Protected>
          } />
          <Route exact path="/process/records/:processId/" element={
            <Protected isLoggedIn={isLoggedIn}><Records /></Protected>
          } />
          <Route exact path="/release" element={
            <Protected isLoggedIn={isLoggedIn}><Release /></Protected>
          } />
          <Route exact path="/release/add" element={
            <Protected isLoggedIn={isLoggedIn}><AddRelease /></Protected>
          } />
            <Route exact path="/release/edit/:recordId" element={
              <Protected isLoggedIn={isLoggedIn}><AddRelease /></Protected>
            } />
          <Route exact path="/release/:releaseID/add" element={
            <Protected isLoggedIn={isLoggedIn}><AddReleaseRecord /></Protected>
          } />
            <Route exact path="/release/:releaseId/edit/:recordId" element={
              <Protected isLoggedIn={isLoggedIn}><AddReleaseRecord /></Protected>
            } />
          <Route exact path="/process/records/:procesId/edit/:recordId" element={
            <Protected isLoggedIn={isLoggedIn}><AddProcessRecords /></Protected>
          } />
          <Route exact path="/process/qa-record/:processId/add" element={
            <Protected isLoggedIn={isLoggedIn}><AddProcessRecords /></Protected>
          } />
          <Route exact path="/process/qa-records/:processId/" element={
            <Protected isLoggedIn={isLoggedIn}><Records /></Protected>
          } />
          <Route exact path="/process/qa-records/:procesId/edit/:recordId" element={
            <Protected isLoggedIn={isLoggedIn}><AddProcessRecords /></Protected>
          } />
          <Route exact path="/process/add" element={
            <Protected isLoggedIn={isLoggedIn}><AddProcess /></Protected>
          } />
          <Route exact path="/process/edit/:recordId" element={
            <Protected isLoggedIn={isLoggedIn}><AddProcess /></Protected>
          } />
          <Route exact path="/performance/:value" element={
            <Protected isLoggedIn={isLoggedIn}><Performance /></Protected>
          } />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </UserCtx.Provider>
    </Router>
  );
}
export default App;