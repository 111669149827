import React, { useEffect, useState } from 'react'
import { getSiteMemory } from "../services/stateAPI";
import AppMenu from '../components/AppMenu';
import { title } from "../config";
import { Table } from 'reactstrap';


const Memory = ({ }) => {
    const [pageTitle, setPageTitle] = useState("Memory");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const dayBeforeYesterday = new Date(today);
    dayBeforeYesterday.setDate(today.getDate() - 2);

    const getPerSapce = (free, size) => {
        const percentageFull = ((size - free) / size) * 100;
        const roundValue = percentageFull.toFixed(2); //Math.round(percentageFull);
        return roundValue;
    };
    const reloadSiteMemory = async () => {
        const data = await getSiteMemory()
        setLoading(false);
        const modifyResult = data.map((e) => {
            const fullMemoryPrDay1 = getPerSapce(e.memoryData.Day1, e.size);
            const fullMemoryPrDay2 = getPerSapce(e.memoryData.Day2, e.size);
            const fullMemoryPrDay3 = getPerSapce(e.memoryData.Day3, e.size);

            const memoryDiffDay1Day2 = e.memoryData.Day2 - e.memoryData.Day1;
            const memoryDiffDay2Day3 = e.memoryData.Day3 - e.memoryData.Day2;

            const modifiedMemoryData = {
                fullMemoryPrDay1: `${fullMemoryPrDay1}`, Day1Memory: `${e.memoryData.Day1}`,
                fullMemoryPrDay2: `${fullMemoryPrDay2}`, Day2Memory: `${e.memoryData.Day2}`,
                fullMemoryPrDay3: `${fullMemoryPrDay3}`, Day3Memory: `${e.memoryData.Day3}`,
            };
            return {
                ...e,
                memoryData: modifiedMemoryData,
                memoryDiffDay1Day2,
                memoryDiffDay2Day3
            };
        });
        const sortedResult = modifyResult.sort((a, b) => b.memoryDiffDay1Day2 - a.memoryDiffDay1Day2);
        setItems(sortedResult);
    };
    const getGBData = (totalSize) => {
        return (totalSize / 1073741824).toFixed(2);
    };
    const getMBData = (totalSize) => {
        return (totalSize / 1048576).toFixed(2);
    };
    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
        reloadSiteMemory();
    }, []);

    return (
        <div>   <div>
            <div className="px-2">
                <h3 className="m-0 p-2">{title} | {pageTitle}</h3>
                <AppMenu />
            </div>
            <div className="p-0 px-2 m-0">
                <div className="px-2">
                    <b>Total:{items.length} &nbsp;&nbsp;</b>
                </div>
                <hr className="p-0 my-2" />
            </div>
            <div className="p-0 m-0 px-2">
                <Table className="table table-hover" responsive hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Total Memory</th>
                            <th>{`${formatDate(today)}`}</th>
                            <th>{`${formatDate(yesterday)}`}</th>
                            <th>{`${formatDate(dayBeforeYesterday)}`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => {
                            return (
                                <tr key={item._id}>
                                    <td style={{ width: "300px" }}>{item.name}</td>
                                    <td>{`${getGBData(item.size)} GB`}</td>
                                    <td>
                                        {item.memoryData.fullMemoryPrDay1 ? (
                                            <div>
                                                <span>
                                                    {item.memoryData.fullMemoryPrDay1}% Full
                                                    <br />
                                                    Avail: {getGBData(item.memoryData.Day1Memory)} GB
                                                    <br />Mem Diff :
                                                    {item.memoryDiffDay1Day2 > 0
                                                        ? <div style={{ color: "red" }} >{getMBData(item.memoryDiffDay1Day2)}MB</div>
                                                        : `(${getMBData(item.memoryDiffDay1Day2)}MB)`}
                                                </span>
                                            </div>
                                        ) : ' '}
                                    </td>
                                    <td>
                                        {item.memoryData.fullMemoryPrDay2 ? (
                                            <div>
                                                <span>
                                                    {item.memoryData.fullMemoryPrDay2}% Full
                                                    <br />
                                                    Avail: {getGBData(item.memoryData.Day2Memory)} GB
                                                    <br />Mem Diff :
                                                    {item.memoryDiffDay2Day3 > 0
                                                        ? <div style={{ color: "red" }} >{getMBData(item.memoryDiffDay2Day3)}MB </div>
                                                        : <div style={{ color: "green" }} >({getMBData(item.memoryDiffDay2Day3)}MB)</div>}
                                                </span>
                                            </div>
                                        ) : ' '}
                                    </td>
                                    <td>
                                        {item.memoryData.fullMemoryPrDay3 ? (
                                            <div>
                                                <span>
                                                    {item.memoryData.fullMemoryPrDay3}% Full
                                                    <br />
                                                    Avail: {getGBData(item.memoryData.Day3Memory)} GB
                                                </span>
                                            </div>
                                        ) : ' '}
                                    </td>
                                </tr>
                            )
                        })
                        }

                    </tbody>
                </Table>
            </div>
            {loading ? "" : ""}

        </div></div>
    )
}

export default Memory