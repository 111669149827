import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { addUserData, getUserById, updateUserData } from "../services/userAPI";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import Select from "react-select";
import { userActiveInactive, userRoles } from "../services/constant";

export default function AddUser() {
  const navigate = useNavigate();
  const { recordId } = useParams();
  const [role, setRole] = useState([]);
  const [active, setActive] = useState([]);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    active: "",
    role: "",
  });
  const [buttonLabel, setBLabel] = useState("Add");
  const loadUser = async (id) => {
    let record = await getUserById(id);
    setUser(record);
    setBLabel("Update");
  };
  const loadRole = async () => {
    let roleData = await userRoles;
    let status = await userActiveInactive;
    setActive(status);
    setRole(roleData);
  };

  useEffect(() => {
    if (recordId) {
      loadUser(recordId);
    }
    loadRole();
  }, [recordId]);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (e) => {
    setUser({ ...user, role: e.value });
  };
  const handleActiveChange = (e) => {
    setUser({ ...user, active: e.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user.name.length) {
      return;
    }
    const newItem = { ...user };
    if (user._id !== undefined) {
      const updatedData = await updateUserData(newItem);
      setUser(updatedData);
    } else {
      await addUserData(newItem);
    }
    navigate("/user");
  };
  return (
    <div>
      <div className="px-2">
        <h3 className="m-0 p-2"> {title} | {buttonLabel} User</h3>
        <AppMenu title="Add User" />
      </div>
      <div className="p-0 m-0 px-2">
        <div className="card p-5">
          <form className="container float-center">
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-3">
                <label className="form-label">Name</label>
                <input
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={user.name}
                  className="form-control"
                  required
                />
              </div>
              <br />
              <br />
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">E-mail</label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={user.email}
                  className="form-control"
                  required
                />
              </div>
              <br />
              <br />
              <div className="col-md-4 col-sm-6 mb-3">
                <span></span>
                <label className="form-label">Password</label>
                <input
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={user.password}
                  className="form-control"
                  required
                />
              </div>
              <br />
              <br />
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-3">
                <label className="form-label">Phone no.</label>
                <input
                  placeholder="phone"
                  name="phone"
                  onChange={handleChange}
                  value={user.phone}
                  className="form-control"
                />
              </div>
              <br />
              <br />
              <div className="col-md-4 col-sm-3 mb-3">
                <label className="form-label">Active</label>
                <Select
                  name="active"
                  className="basic-single mb-0"
                  options={active}
                  getOptionValue={(active) => active.value}
                  getOptionLabel={(active) => active.name}
                  onChange={handleActiveChange}
                  autoFocus={true}
                  value={active.find((i) => i.value === user.active)}
                />
              </div>
              <br />
              <br />
              <div className="col-md-4 col-sm-3 mb-3">
                <label className="form-label">Role</label>
                <Select
                  name="role"
                  className="basic-single mb-0"
                  options={role}
                  getOptionValue={(role) => role.value}
                  getOptionLabel={(role) => role.name}
                  onChange={handleRoleChange}
                  autoFocus={true}
                  value={role && role.find((i) => i.value === user.role)}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-2 col-sm-6">
                <input
                  type="button"
                  onClick={handleSubmit}
                  value={buttonLabel}
                  className="btn btn-primary btn-block"
                />
              </div>
              &nbsp;&nbsp;
              <div className="col-md-2 col-sm-6">
                <input
                  type="button"
                  value="Cancel"
                  className="btn btn-block btn-secondary"
                  onClick={() => {
                    navigate("/user");
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
