import axios from "axios";

export const deployAPI = async (url) => {
    const result = await axios.get(url).catch((error) => {
        console.log("Error:", error.message);
        return error?.response;
    });
    return result.data;
};
// export const getReport = async (url) => {
//     try {
//         const response = await axios.get(url);
//         return response.data;
//     } catch (error) {
//         console.error("Error:", error.message);
//         return null; // or handle error as per your requirement
//     }
// };

export const getReport = async (url, type) => {
    try {
        const response = await axios.get(url).catch((err) => {return err?.response});
        if(response?.status == 404){
            return response; 
        }
        if(response){
        return { type :type ,count: response.data.loginCount };
        }else{
            return [];
        }
    } catch (error) {
        console.error(`Error fetching ${type} data:`, error.message);
        return { [type]: null };
    }
};