import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { addProcessData, getProcessById, updateProcessData } from "../services/processAPI";
import { getUserDetails } from "../services/userStorage";
import AppMenu from "../components/AppMenu";

export default function AddProcess() {
    const { recordId } = useParams();
    const userDetails = getUserDetails();
    const [process, setProcess] = useState({
        processName: "",
        createdAt: "",
        updatedAt: "",
        updatedBy: {
            id: userDetails?._id || "",
            name: userDetails?.name | "",
            email: userDetails?.email || ""
        },
        steps: [""],
        qaSteps: [""],
    });

    const [buttonLabel, setButtonLabel] = useState("Add");

    const loadProcess = async (id) => {
        try {
            const record = await getProcessById(id);
            setProcess(record);
            setButtonLabel("Update");
        } catch (error) {
            console.error("Failed to load process data:", error);
        }
    };
  
    useEffect(() => {
        console.log("processId", recordId);
        if (recordId) {
            loadProcess(recordId);
        }
    }, [recordId]);

    const handleChange = (e) => {
        setProcess({ ...process, [e.target.name]: e.target.value });
    };

    const handleStepChange = (e, index) => {
        const newSteps = [...process.steps];
        newSteps[index] = e.target.value;
        setProcess({ ...process, steps: newSteps });
    };

    const addStep = () => {
        setProcess({ ...process, steps: [...process.steps, ""] });
    };

    const removeStep = (index) => {
        const newSteps = process.steps.filter((_, i) => i !== index);
        setProcess({ ...process, steps: newSteps });
    };

    const handleQaStepChange = (e, index) => {
        const newQaSteps = [...process.qaSteps];
        newQaSteps[index] = e.target.value;
        setProcess({ ...process, qaSteps: newQaSteps });
    };

    const addQaStep = () => {
        setProcess({ ...process, qaSteps: [...process.qaSteps, ""] });
    };

    const removeQaStep = (index) => {
        const newQaSteps = process.qaSteps.filter((_, i) => i !== index);
        setProcess({ ...process, qaSteps: newQaSteps });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!process.processName.trim()) {
            return;
        }
        const now = new Date().toISOString();
        const newItem = {
            ...process,
            updatedAt: now,
            updatedBy: {
                id: userDetails?._id,
                name: userDetails?.name,
                email: userDetails?.email,
            },
            ...(process._id ? {} : {
                createdAt: now, createdBy: {
                    id: userDetails?._id,
                    name: userDetails?.name,
                    email: userDetails?.email,
                },
            }),
        };
        try {
            if (recordId) {
                await updateProcessData(newItem);
            } else {
                await addProcessData(newItem);
            }
            window.history.back();
        } catch (error) {
            console.error("Failed to submit form:", error);
        }
    };

    return (
        <div>
            <div className="px-2">
                <h3 className="m-0 p-2">{buttonLabel} Process</h3>
                <AppMenu />
            </div>
            <div className="p-0 m-0 px-2 pb-2">
                <div className="card p-5">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-4 col-sm-12 mb-3">
                                <label>Process Name</label>
                                <input
                                    type="text"
                                    name="processName"
                                    value={process.processName}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-3">
                                <label>Steps</label>
                                {process?.steps?.map((step, index) => (
                                    <div className="input-group mb-3" key={index}>
                                        <input
                                            type="text"
                                            value={step}
                                            onChange={(e) => handleStepChange(e, index)}
                                            className="form-control"
                                            required
                                        />
                                        {process.steps.length > 1 && (
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeStep(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={addStep}
                                >
                                    Add More Step
                                </button>
                            </div>
                            <div className="col-md-4 col-sm-12 mb-3">
                                <label>QA. Steps</label>
                                {process?.qaSteps?.map((step, index) => (
                                    <div className="input-group mb-3" key={index}>
                                        <input
                                            type="text"
                                            value={step}
                                            onChange={(e) => handleQaStepChange(e, index)}
                                            className="form-control"
                                            required
                                        />
                                        {process.qaSteps.length > 1 && (
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeQaStep(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={addQaStep}
                                >
                                    Add More QA Step
                                </button>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-success">
                            {buttonLabel}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
