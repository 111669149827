import React, { useEffect, useState } from 'react'
import { getReport } from '../services/Deploy';

const UserInfoBySite = ({ deployData }) => {
    const [count, setCount] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    const today = new Date();
    const todayDate = today.toISOString().split('T')[0]; // today's date in YYYY-MM-DD format
    const weekDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    const monthDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30).toISOString().split('T')[0];
    const yearDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).toISOString().split('T')[0];

    useEffect(() => {
        const fetchData = async () => {
            const urls = [
                { url: `https://cms.${deployData.domain}/custom/get-site-login-details`, type: 'Daily user' },
                { url: `https://cms.${deployData.domain}/custom/get-site-login-details?startDate=${weekDate}&endDate=${todayDate}`, type: 'Weekly user' },
                { url: `https://cms.${deployData.domain}/custom/get-site-login-details?startDate=${monthDate}&endDate=${todayDate}`, type: 'Monthly user' },
                { url: `https://cms.${deployData.domain}/custom/get-site-login-details?startDate=${yearDate}&endDate=${todayDate}`, type: 'Yearly user' }
            ];
            // const urls = [
            //     { url: `https://cms.${deployData.domain}/custom/get-site-login-details`, type: 'Daily user' },
            //     { url: `https://cms.${deployData.domain}/custom/get-site-login-details?startDate=2024-02-21&endDate=2024-02-28`, type: 'Weekly user' },
            //     { url: `https://cms.${deployData.domain}/custom/get-site-login-details?startDate=2024-01-28&endDate=2024-02-28`, type: 'Monthly user' },
            //     { url: `https://cms.${deployData.domain}/custom/get-site-login-details?startDate=2023-02-28&endDate=2024-02-28`, type: 'Yearly user' }
            // ];
            const results = await Promise.all(urls.map(({ url, type }) => getReport(url, type)));
            setCount(results);
            setisLoading(false);
        };
        if (deployData.domain) {
            fetchData();
        }
    }, [deployData]);
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">User Info</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='text-center p-3'>
                                <h3>{deployData?.name}</h3>
                            </div>
                            <ol className="list-group list-group-numbered">
                                {isLoading ? (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "20vh" }} >
                                        <div className="spinner-border">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) :
                                    count && count.some(e => e.status === 404) ?
                                        <h4 className='text-center'>Error occurred while fetching data</h4> :
                                        count?.map((e, index) => {
                                            return (
                                                <li className="list-group-item d-flex justify-content-between align-items-start" key={index}>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{e.type}</div>
                                                        {/* Content for list item */}
                                                    </div>
                                                    {/* <span className="badge bg-primary rounded-pill"> <i className="fa fa-refresh" aria-hidden="true" /> */}
                                                    {/* </span> */}
                                                    <span className="badge bg-primary rounded-pill p-2">{e.count}</span>
                                                </li>
                                            )
                                        })
                                }
                            </ol>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserInfoBySite
