import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

const RecordListTable = ({ items, doDelete, doEdit }) => {
    const [localRecords, setLocalRecords] = useState([]);
    const [toggleSorting, setToggleSorting] = useState(0);
    useEffect(() => {
        setLocalRecords(items);
    }, [items]);

    const sortTable = (field) => {
        let sortedData = [];

        if (toggleSorting === 1) {
            sortedData = [...localRecords].sort((a, b) => a[field] > b[field] ? 1 : -1);
            setToggleSorting(0);
        } else {
            sortedData = [...localRecords].sort((a, b) => b[field] > a[field] ? 1 : -1);
            setToggleSorting(1);
        }
        setLocalRecords(sortedData);
    };

    return (
        <div className="p-0 m-0 px-2">
            <Table className="table table-hover mb-5" responsive hover>
                <thead>
                    <tr>
                        <th onClick={() => sortTable("recordName")} className="cursor-pointer">Process Name</th>
                        <th onClick={() => sortTable("siteName")} className="cursor-pointer">Site</th>
                        <th onClick={() => sortTable("createdBy")} className="cursor-pointer">Created By</th>
                        <th onClick={() => sortTable("createdBy")} className="cursor-pointer">Updated By</th>
                        <th onClick={() => sortTable("createdAt")} className="cursor-pointer">Created At</th>
                        <th onClick={() => sortTable("lastUpdated")} className="cursor-pointer">Last Updated</th>
                        <th onClick={() => sortTable("lastUpdated")} className="cursor-pointer">Completed</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    {localRecords?.map((record) =>
                    // { console.log("record",record);

                    (
                        <tr key={record._id}>
                            <td>{record.record.processName}</td>
                            <td>{record.record.siteName}</td>
                            <td>{record.record.createdBy}</td>
                            <td>{record.record.updateBy}</td>
                            <td>{moment(record.record.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                            <td>{moment(record.record.lastUpdated).format("DD/MM/YYYY HH:mm")}</td>
                            <td>
                                {record.record.steps.filter(step => step.result === true).length}/{record.record.steps.length}
                                {record.record.steps.filter(step => step.result === true).length === record.record.steps.length && (
                                    <span style={{ color: 'green', marginLeft: '5px' }} className='fa-solid fa-thumbs-up'></span>
                                )}
                            </td>
                            <td>
                                <div className="dropdown pe-2">
                                    <a href="#" role="button" data-bs-toggle="dropdown">
                                        <i className="fa-solid fa-bars"></i>
                                    </a>
                                    <div className="dropdown-menu p-2">
                                        <ul className="d-flex p-0 m-0">
                                            <li className="list-unstyled">
                                                <i
                                                    className="fas fa-edit btn btn-primary m-1"
                                                    onClick={() => doEdit(record._id)}
                                                ></i>
                                            </li>
                                            <li className="list-unstyled">
                                                <i
                                                    className="fa-solid fa-trash-can btn btn-danger m-1"
                                                    onClick={() => doDelete(record._id)}
                                                ></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>

                        </tr>
                    )
                        // }
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default RecordListTable;
