import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap';

const ErrorList = ({ items, loading }) => {
    const [localItems, setlocalItems] = useState([...items]);
    const [toggleSorting, setToggleSorting] = useState(0);

    const sortTable = (field) => {
        const sortedData = [...localItems].sort((a, b) => {
            const compareResult = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            return toggleSorting === 1 ? -compareResult : compareResult;
        });
        setToggleSorting((prev) => (prev === 1 ? 0 : 1));
        setlocalItems(sortedData);
    };
    const outLogURL = (domain) => {
        const url = `https://core.${domain}/out-log`;
        return url;
    };
    const errorLogURL = (domain) => {
        const url = `https://core.${domain}/error-log`;
        return url;
    };
    useEffect(() => {
        setlocalItems(items);
    }, [items]);
    return (
        <>
            {loading ?
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <div className="spinner-border">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                <div className='p-0 m-0 px-2'>
                    <Table>
                        <thead>
                            <tr>
                                <th onClick={() => sortTable("name")} >Name</th>
                                <th onClick={() => sortTable("domain")}>Domain</th>
                                <th onClick={() => sortTable("cms")}>CMS Status</th>
                                <th onClick={() => sortTable("status")}>Proxy Status</th>
                                <th onClick={() => sortTable("time")}>Time </th>
                                <th >Out Log</th>
                                <th>Error Log</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {localItems.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.name} </td>
                                    <td>{item.domain} </td>
                                    <td>{item.cms} </td>
                                    <td>{item.status} </td>
                                    <td>
                                        {moment(item.time)
                                            .zone("Asia/Kolkata")
                                            .format("hh:mm A - DD/MM/YYYY")}
                                    </td>
                                    <td>
                                        <a href={outLogURL(item.domain)}>
                                            <i
                                                className="fa-solid fa-gears btn btn-success"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </td>

                                    <td>
                                        <a href={errorLogURL(item.domain)}>
                                            <i
                                                className="fa-solid fa-circle-exclamation btn btn-danger"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default ErrorList